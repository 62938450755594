import React from "react"
import Container from "../components/Container"
import H1 from "../components/H1"
import H2 from "../components/H2"
import HeroHeader from "../components/HeroHeader"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const MusicPage = ({ data }) => (
  <Layout>
    <SEO title="Music" keywords={[`music`]} />
    <HeroHeader textAlign="left">
      <Container>
        <H1>Music</H1>
        <H2>I'm a classically-trained percussionist and pianist.</H2>
      </Container>
    </HeroHeader>
    <Container>
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
    </Container>
  </Layout>
)

export default MusicPage

export const query = graphql`
  {
    markdownRemark(frontmatter: { id: { eq: "music" } }) {
      html
    }
  }
`
