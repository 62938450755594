import styled from "styled-components"
import React from "react"
import { mediaQueries } from "./GlobalStyles"

const Styled = styled.div`
  max-width: 50em;
  margin: 2em auto;
  padding: 0 2em;
  ${props =>
    props.flex === true &&
    `display: flex; ${mediaQueries.small} { flex-wrap: wrap; }`}
`

export default ({ children, ...props }) => (
  <Styled {...props}>{children}</Styled>
)
