import styled from "styled-components"
import React from "react"
import { colors, mediaQueries } from "./GlobalStyles"

const StyledH2 = styled.h2`
  margin: 0;
  font-family: merriweather, serif;
  color: ${colors.darkGrey};
  font-weight: 400;
  ${mediaQueries.small} {
    font-size: 1.2em;
  }
`

export default ({ children }) => <StyledH2>{children}</StyledH2>
