import styled from "styled-components"
import React from "react"
import { mediaQueries } from "../components/GlobalStyles"

const StyledH1 = styled.h1`
  font-size: 3em;
  ${mediaQueries.small} {
    font-size: 2.2em;
  }
  margin: 0.2em 0;
  font-family: Montserrat, helvetica neue, Helvetica, Arial, sans-serif;
`

export default ({ children }) => <StyledH1>{children}</StyledH1>
