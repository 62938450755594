import styled from "styled-components"
import React from "react"
import { colors, mediaQueries } from "./GlobalStyles"

const StyledHeader = styled.header`
  background-color: ${colors.yellow};
  border-left: 0;
  border-right: 0;
  padding: 2em;
  text-align: ${props => (props.textAlign ? props.textAlign : "center")};
  box-shadow: 1px 0px 5px 0px rgba(66, 62, 55, 0.75);
  ${mediaQueries.small} {
    padding: 1em;
  }
`

export default ({ children, ...props }) => (
  <StyledHeader {...props}>{children}</StyledHeader>
)
